<template>
  <div class="settings-page page-container">
    <div class="card">
      <div class="card-header">
        <div class="card-title h4">Configurações</div>
      </div>
      <div class="card-body">
        <places :places="places" @add="addPlace" @remove="removePlace"></places>
        <div class="p-2"></div>
        <rooms :rooms="rooms" @add="addRoom" @remove="removeRoom" :places="places"></rooms>
      </div>
    </div>
  </div>
</template>

<script>
import Rooms from './components/Rooms.vue';
import Places from './components/Places.vue';

export default {
  components: {
    Rooms,
    Places,
  },
  data() {
    return {
      places: [],
      rooms: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$hospitalService.get('/place', {
        params: {
          limit: 20,
          offset: 0,
          hospital: localStorage.hospital,
        },
      }).then(({ data }) => {
        this.places = data;
        this.rooms = data.reduce((acc, place) => {
          const placeRooms = place.rooms.map((room) => ({ ...room, place: { _id: place._id, name: place.name } }));
          return [...acc, ...placeRooms];
        }, []);
      }).catch(() => { });
    },
    addRoom(room) {
      this.rooms.push(room);
    },
    removeRoom(roomId) {
      this.rooms = this.rooms.filter((room) => room._id !== roomId);
    },
    addPlace(place) {
      this.places.push(place);
    },
    removePlace(placeId) {
      this.places = this.places.filter((place) => place._id !== placeId);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.settings-page {}
</style>
