<template>
  <div class="place-settings">
    <st-tile :icon="['far', 'chevron-right']" :centered="true" size="md">
      <div class="tile-title">Cadastro de Locais</div>
      <div class="tile-subtitle">Locais de atendimento</div>
      <template v-slot:action>
        <button class="btn btn-primary btn-icon btn-icon-left" @click="open()">
          <fa-icon :icon="['fad', 'plus']"></fa-icon> Novo
        </button>
      </template>
    </st-tile>

    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Nome da local</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(place, i) in places" :key="place._id">
          <td style="width: 40px;">{{ i + 1 }}</td>
          <td>{{ place.name }}</td>
          <td class="text-right">
            <button class="btn btn-sm" @click="open(place)">Editar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <st-modal v-model="modal" title="Cadastro de Locais" class="place-modal">
    <div class="columns">
      <div class="column col-12 col-mx-auto my-2">
        <div class="form-group">
          <label for="name" class="form-label">Nome da local</label>
          <input type="text" id="name" class="form-input" v-model="form.name" placeholder="Ex: Recepção 01">
        </div>
      </div>
      <div class="columns col-6 col-mx-auto my-2">
        <div class="column col-12">
          <label for="ticket-prefix" class="form-label">Tamanho da senha</label>
        </div>
        <div class="column col-12">
          <input type="number" id="ticket-prefix" class="form-input" v-model="form.paddingNumber" placeholder="Ex: 4">
        </div>
      </div>
      <div class="columns col-6 col-mx-auto my-2">
        <div class="column col-12 ">
          <label for="printer-model" class="form-label">Modelo da impressora</label>
        </div>
        <div class="column col-12">
          <select id="printer-model" class="form-select" v-model="form.printerModel">
            <option v-for="model in printerModels" :value="model" :key="model">{{ model }}</option>
          </select>
        </div>
      </div>
      <div class="columns col-12 col-mx-auto my-2">
        <div class="col-12 divider text-center" data-content="Opções de senhas"></div>
      </div>
      <div class="columns col-6 col-mx-auto my-2">
        <div class="column col-12">
          <label for="ticket-name" class="form-label">Nome da senha</label>
        </div>
        <div class="column col-12">
          <input type="text" id="ticket-name" class="form-input" v-model="form.ticket.title" placeholder="Ex: Exame ">
        </div>
      </div>
      <div class="columns col-6 col-mx-auto my-2">
        <div class="column col-12">
          <label for="ticket-prefix" class="form-label">Prefixo da senha</label>
        </div>
        <div class="column col-12">
          <input type="text" id="ticket-prefix" class="form-input" v-model="form.ticket.prefix" placeholder="Ex: EX ">
        </div>
      </div>
      <div class="columns col-12 col-mx-auto my-2">
        <div class="column col-3">
          <label class="form-switch">
            <input type="checkbox" v-model="form.ticket.hasPreference">
            <i class="form-icon"></i> Preferencia
          </label>
        </div>
        <div class="column col-lg-auto col-4 text-right">
          <button class="btn btn-primary" @click="addAttendance(form.ticket)">
            <fa-icon :icon="['fad', 'plus']"></fa-icon>
          </button>
        </div>
      </div>

    </div>
    <div class="panel mt-2">
      <div class="panel-header">
        <div class="panel-title h6">Opções de Senhas</div>
      </div>
      <div class="panel-body ticket">
        <template v-if="form.attendance.length > 0">
          <st-tile :centered="true" size="md" v-for="(attendance, i) in form.attendance" v-bind:key="i">
            <div class="tile-title">{{ attendance.title }} | <b>Prefixo:</b> {{ attendance.prefix }}</div>
            <div class="tile-subtitle">{{ attendance.hasPreference ? 'Tem preferencia' : 'Não tem preferencia' }}</div>
            <template v-slot:action>
              <button class="btn btn-error" @click="removeAttendance(attendance)">
                <fa-icon :icon="['fad', 'trash']"></fa-icon>
              </button>
            </template>
          </st-tile>
        </template>
        <template v-else>
          <div class="empty">

            <p class="empty-title h5">Não há registros</p>
          </div>
        </template>
      </div>
    </div>
    <template v-slot:footer>
      <div class="columns">
        <div class="column text-left" v-if="form._id">
          <button class="btn btn-error" @click="remove">Excluir</button>
        </div>
        <div class="column">
          <button class="btn mr-2" @click="modal = false">Sair</button>
          <button class="btn btn-primary" @click="save">Salvar</button>
        </div>
      </div>
    </template>
  </st-modal>
</template>

<script>
import { printerModels } from '@/config';

export default {
  emits: [
    'add',
    'remove',
  ],
  data() {
    return {
      form: this.blankForm(),
      modal: false,
      printerModels,
    };
  },
  props: {
    places: {
      type: Object,
    },
  },
  methods: {
    open(data = null) {
      this.form = this.blankForm();
      if (data) {
        this.form = { ...this.form, ...data };
      }
      this.modal = true;
    },
    addAttendance(ticket) {
      this.form.attendance.push(ticket);
      this.form.ticket = this.blankTicket();
    },
    removeAttendance(option) {
      this.form.attendance = this.form.attendance.filter((attendance) => attendance.title !== option.title);
    },
    save() {
      const formData = {
        name: this.form.name,
        attendance: this.form.attendance,
        paddingNumber: this.form.paddingNumber,
        printerModel: this.form.printerModel,
      };

      const isNew = !this.form._id;

      const request = isNew
        ? this.$hospitalService.post('/place', {
          ...formData,
          hospital: localStorage.hospital,
        })
        : this.$hospitalService.patch(`/place/${this.form._id}`, formData);

      request.then(({ data }) => {
        if (isNew) {
          this.form._id = data._id;
          this.$emit('add', data);
        } else {
          const found = this.places.find(({ _id }) => _id === this.form._id);
          found.name = formData.name;
        }
        this.modal = false;
      }).catch(() => { });
    },
    remove() {
      this.$hospitalService.delete(`/place/${this.form._id}`)
        .then(() => {
          this.$emit('remove', this.form._id);
          this.modal = false;
        })
        .catch(() => { });
    },
    blankTicket() {
      return {
        name: '',
        hasPreference: false,
      };
    },
    blankForm() {
      return {
        id: null,
        name: '',
        attendance: [],
        ticket: this.blankTicket(),
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.place-modal {
  .ticket {
    margin-bottom: $layout-spacing;
  }

  .check {
    display: flex;
    align-items: center;
  }

  .add-button {
    display: flex;
    justify-content: flex-end;
  }
}

.place-settings {

  .tile {
    border-radius: $border-radius;
    background-color: $gray-color-ultralight;
    margin-bottom: $layout-spacing-sm;

    .tile-icon svg {
      color: $gray-color;
    }

    .tile-title {
      color: $primary-color;
      font-weight: 600;
    }

    .tile-subtitle {
      margin-bottom: $border-width;
    }
  }
}
</style>
