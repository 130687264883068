<template>
  <div class="login-page">
    <div class="container">
      <!-- <button class="btn btn-primary btn-block" @click="login">Entrar</button> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      public: '',
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.public = this.$route.query['public-id'];

      if (!this.public) {
        localStorage.clear();
        this.$router.push('/404');
      }

      await this.$hospitalService.get(`/hospital/${this.public}`, {
        params: {
          'type-id': 'public',
        },
      }).then(({ data }) => {
        localStorage.hospital = data._id;
        localStorage.hospitalName = data.name;
        localStorage.public = this.$route.query['public-id'];
        this.$router.push('/');
      }).catch(() => {
        this.$router.push('/404');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';

.login-page {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .login-card {
    padding: $layout-spacing-lg;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $unit-3;
  }

  .form-actions {
    margin: $unit-6 0;
  }

  .logo img {
    width: 12rem;
  }
}
</style>
