<template>
  <div class="room-settings">
    <st-tile :icon="['far', 'chevron-right']" :centered="true" size="md">
      <div class="tile-title">Cadastro de salas</div>
      <div class="tile-subtitle">Salas são os locais/guichês de atendimento</div>
      <template v-slot:action>
        <button class="btn btn-primary btn-icon btn-icon-left" @click="open()">
          <fa-icon :icon="['fad', 'plus']"></fa-icon> Novo
        </button>
      </template>
    </st-tile>

    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Nome da sala</th>
          <th>Local</th>
          <th>Sala de Especialista</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(room, i) in rooms" :key="room._id">
          <td style="width: 40px;">{{ i + 1 }}</td>
          <td>{{ room.name }}</td>
          <td>{{ room.place.name }}</td>
          <td>{{ room.specialistRoom ? 'Sim' : 'Não' }}</td>
          <td class="text-right">
            <button class="btn btn-sm" @click="open(room)">Editar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <st-modal v-model="modal" title="Cadastro de sala">
    <div class="form-group">
      <label for="name" class="form-label">Nome da sala/guichê</label>
      <input type="text" id="name" class="form-input" v-model="form.name" placeholder="Ex: Recepção 01">
    </div>
    <div class="form-group">
      <label for="type" class="form-label">Local</label>
      <select id="type" class="form-select" v-model="form.place._id">
        <option v-for="place in places" :value="place._id" :key="place._id">{{ place.name }}</option>
      </select>
    </div>
    <label class="form-switch">
      <input id="specialistRoom" type="checkbox" v-model="form.specialistRoom">
      <i class="form-icon"></i> Sala de Especialista
    </label>
    <template v-slot:footer>
      <div class="columns">
        <div class="column text-left" v-if="form._id">
          <button class="btn btn-error" @click="remove">Excluir</button>
        </div>
        <div class="column">
          <button class="btn mr-2" @click="modal = false">Sair</button>
          <button class="btn btn-primary" @click="save">Salvar</button>
        </div>
      </div>
    </template>
  </st-modal>
</template>

<script>
export default {
  data() {
    return {
      form: this.blankForm(),
      modal: false,
    };
  },
  emits: ['add', 'remove'],
  props: {
    rooms: {
      type: Object,
    },
    places: {
      type: Object,
    },
  },
  methods: {
    open(data = null) {
      this.form = this.blankForm();
      if (data) {
        this.form = { ...this.form, ...data };
      }
      this.modal = true;
    },
    save() {
      const formData = {
        name: this.form.name,
        place: this.form.place._id,
        specialistRoom: this.form.specialistRoom,
        hospital: localStorage.hospital,
      };

      const isNew = !this.form._id;

      const request = isNew
        ? this.$hospitalService.post('/room', formData)
        : this.$hospitalService.patch(`/room/${this.form._id}`, formData);

      request.then(({ data }) => {
        if (isNew) {
          this.form._id = data._id;
          this.$emit('add', data);
        } else {
          const found = this.rooms.find(({ _id }) => _id === this.form._id);
          found.name = data.name;
          found.place._id = data.place._id;
          found.place.name = data.place.name;
          found.specialistRoom = data.specialistRoom;
        }
        this.modal = false;
      }).catch(() => { });
    },
    remove() {
      this.$hospitalService.delete(`/room/${this.form._id}`)
        .then(() => {
          this.$emit('remove', this.form._id);
          this.modal = false;
        })
        .catch(() => { });
    },
    blankForm() {
      return {
        _id: null,
        name: '',
        specialistRoom: false,
        place: {
          _id: '',
          name: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.room-settings {
  .tile {
    border-radius: $border-radius;
    background-color: $gray-color-ultralight;
    margin-bottom: $layout-spacing-sm;

    .tile-icon svg {
      color: $gray-color;
    }

    .tile-title {
      color: $primary-color;
      font-weight: 600;
    }

    .tile-subtitle {
      margin-bottom: $border-width;
    }
  }
}
</style>
